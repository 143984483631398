import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  TextField,
  Typography,
  Button,
} from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { CreateStore, generateJaneToken } from "../../services/Constant";
import AuthService from "../../services/AuthService";
import { AuthResponse } from "../../core/models/AuthResponse";
import { ApiError } from "../../core/webservice/ApiError";
import Loader from "../commonComponents/Loader";
import PartnerService from "../../services/PartnerService";
import Partner from "../../core/models/Partner";
import { styled } from "@mui/material/styles";
import PartnerManager from "../../core/utils/PartnerManager";
import { IContextService } from "../../services/IContextService";
import ContextService from "../../services/ContextService";
import { toggleBox } from "./toggleBox";
import TextUtils from "../../core/utils/TextUtils";
declare var $: any;

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#d7d2da",
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#d7d2da",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#d7d2da",
    },
    "&:hover fieldset": {
      borderColor: "#d7d2da",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#d7d2da",
    },
  },
});

const AccountCreation = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();
  const [clientId, setClientId] = useState<string>("");
  const [clientKey, setClientKey] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const context: IContextService = useContext(ContextService);

  const CreateAccount = (): void => {
    PartnerService.createPartner(clientId, clientKey)
      .then((result: Partner) => {
        fetchPartnerInfo();
        CreateStore();
      })
      .catch((error: ApiError) => {
        // console.log("error code:" + error.errorCode);
        // console.log("error message:" + error.message);
      });
  };

  useEffect(() => {
    $(".animateBox1").fadeToggle(1000);
    $(".animateBox2").fadeToggle(1000);
    $(".animateBox3").fadeToggle(1000);
  });

  const linkAccount = async (): Promise<void> => {
    AuthService.generateJaneRootsToken(clientId, clientKey)
      .then((authResponse: AuthResponse) => {
        if (authResponse) {
          toggleBox();
          setIsOpen(!isOpen);
          CreateAccount();
        }
      })
      .catch((error: ApiError) => {
        // console.log("error code:" + error.errorCode);
        // console.log("error message:" + error.message);
        setError(true);
      });
  };

  const nextPage = (): void => {
    navigate("/accountSetup");
  };

  const fetchPartnerInfo = async (): Promise<boolean> => {
    try {
      const data = await PartnerService.getPartnerInfo();
      if (data) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      PartnerManager.shared().doLogout();
      context.setAccessTokenValid(false);
      context.setPanel("panel1");
      // context.setHeader({ length: 0, index: 0 })
      navigate("/");
      // console.log(error);
    }
  };

  useEffect(() => {
    const flagValue = async (): Promise<void> => {
      const reDirect = await fetchPartnerInfo();
      let store: boolean;
      let tokenValue: boolean;
      if (reDirect) {
        tokenValue = await generateJaneToken();
      }
      if (tokenValue) {
        store = await CreateStore();
      }
      if (store && reDirect) {
        navigate("/accountSetup");
      } else {
        setLoader(false);
      }
    };
    flagValue();
  }, []);

  const onLogout = (): void => {
    PartnerManager.shared().doLogout();
    context.setAccessTokenValid(false);
    context.setPanel("panel1");
    navigate("/");
  };

  return (
    <>
      <div className="wrap">
        {loader && <Loader />}

        {!loader && (
          <Container
            className="form-j"
            maxWidth="lg"
            style={{ height: "100%" }}
          >
            <Box
              sx={{
                position: "absolute",
                float: "right",
                right: "0",
                paddingTop: "15px",
                paddingRight: "15px",
              }}
            >
              <img
                onClick={onLogout}
                className="usr"
                src="../assets/images/header/logout.svg"
              />
            </Box>
            <Box>
              <Typography variant="h2" className="accountHead">
                Account Creation
                {/* <Box className="subLine"> </Box> */}
              </Typography>
            </Box>

            <Grid
              sx={{ mt: 8 }}
              container
              spacing={2}
              style={{ width: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={5}
                md={5}
                lg={7}
                // style={{ paddingLeft: "150px" }}
              >
                <div>
                  <Typography variant="h5" className="accountHead_sub">
                    Link Your Jane Account
                  </Typography>

                  <Typography
                    sx={{ mt: 3, mb: 2 }}
                    variant="body2"
                    component="p"
                    gutterBottom
                    className="account_prea"
                  >
                    We'll import all your product data automatically to get you
                    started!
                  </Typography>
                  <Grid md={10} container>
                    <CssTextField
                      fullWidth
                      placeholder="Enter Client ID"
                      value={clientId}
                      onChange={(e) => setClientId(e.target.value)}
                      name="clientid"
                      autoComplete="client Id"
                      autoFocus
                      variant="standard"
                      style={{ marginTop: "30px" }}
                      className="loginPageInput"
                    />
                  </Grid>
                  <Typography component="p" className="acc_sub">
                    Your login name to Jane.
                  </Typography>

                  <Box className="clear"></Box>
                  <Grid md={10} container>
                    <CssTextField
                      fullWidth
                      placeholder="Enter Client Key"
                      value={clientKey}
                      onChange={(e) => setClientKey(e.target.value)}
                      name="clientkey"
                      autoComplete="client Key "
                      variant="standard"
                      style={{ marginTop: "30px" }}
                      className="loginPageInput"
                    />
                  </Grid>

                  <Typography component="p" className="acc_sub">
                    The API key Jane created for your account.
                  </Typography>

                  <Box className="clear"></Box>

                  {error && (
                    <Box className="validate_acc_creation">
                      <p>Please enter valid Client Id and key</p>
                    </Box>
                  )}

                  <Grid md={10} container style={{ position: "relative" }}>
                    <Button
                      style={{ position: "absolute", right: "0" }}
                      onClick={linkAccount}
                      className={
                        clientId.length > 0 && clientKey.length > 0
                          ? "active_btn_linkAccountBtm"
                          : "unactive_btn_linkAccountBtm"
                      }
                      sx={{ mt: 1, mb: 1 }}
                      variant="contained"
                      disabled={
                        clientId.length > 0 && clientKey.length > 0
                          ? false
                          : true
                      }
                    >
                      Link
                    </Button>
                  </Grid>
                </div>
              </Grid>

              <Grid
                className="block-line-col"
                item
                xs={12}
                md={3}
                sm={3}
                lg={1}
              >
                <Box className="line-col" sx={{ mr: 1 }}></Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                style={{ position: "relative" }}
              >
                <Box className="janeAcc">
                  <img src="../assets/images/accountBlack.svg" />
                </Box>

                <Box className="accordion linkJan accCre">
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<AddIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="account_Head">
                        Where can I find my Client ID?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="preContent">
                        This is your client id assigned by IHeartJane. If you do
                        not have this, the Jane team will provide you with a
                        Client ID, please reach out to us or your account
                        manager at Jane to get the details.
                        {/* <Box><a className='showme'>Show me</a></Box> */}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      sx={{ mt: 2 }}
                      expandIcon={<AddIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="account_Head">
                        Where can I find my Client Key?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="preContent">
                        The IHeartJane will provide you with a Client key,
                        please reach out to us or your account manager to get
                        the details.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      sx={{ mt: 2 }}
                      expandIcon={<AddIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="account_Head">
                        What if I don’t have my own web domain name?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="preContent">
                        If you don’t own any custom domain, you can still go
                        online with Jane’s default domain. Jane allows its users
                        to create an online store using a default domain. You
                        can add your custom domain after going live.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion>
                    <AccordionSummary
                      sx={{ mt: 2 }}
                      expandIcon={<AddIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="account_Head">
                        What information is imported from the Jane platform?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="preContent">
                        Your Account information and Product information will be
                        synced from the Jane Platform.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Grid>
            </Grid>

            {isOpen && (
              <Grid
                style={{
                  position: "fixed",
                  background: "#00000050",
                  width: "100%",
                  height: "100vh",
                  top: "0",
                  left: "0",
                }}
              >
                <Box
                  style={{
                    top: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bottom: "0",
                    position: "absolute",
                    width: "100%",
                  }}
                >
                  <Grid
                    className="popupBox"
                    item
                    xs={12}
                    md={6}
                    sm={6}
                    lg={6}
                    style={{ position: "relative", backgroundColor: "#FFF" }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Box className="sideImg">
                          <img src="../assets/images/popupside.svg" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Typography variant="h3" sx={{ mb: 2 }} color="inherit">
                          STATUS
                        </Typography>

                        <div className="statusHeight">
                          <div className="blockLine">
                            <div
                              className="firstLoad"
                              style={{ display: "none" }}
                            >
                              <div className="loadingAnimate">
                                <div className="_name">
                                  <div className="loadG">
                                    <div className="loaderGif">
                                      <img src="../assets/images/sandglass-loader.gif" />
                                      <div className="lineStatus"></div>
                                    </div>
                                  </div>

                                  <p>Authenticating</p>
                                </div>
                                <div className="nameAddress">
                                  <div className="loadersvg">
                                    <svg
                                      className="checkmark"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className="checkmark__circle"
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className="checkmark__check"
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>

                                    <div className="lineStatus"></div>
                                  </div>
                                  <p>
                                    <b>Authenticating</b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="blockLine">
                            <div
                              className="secondLoad"
                              style={{ display: "none" }}
                            >
                              <div className="loadingAnimate">
                                <div className="_name1">
                                  <div className="loadG">
                                    <div className="loaderGif">
                                      <img src="../assets/images/sandglass-loader.gif" />
                                      <div className="lineStatus"></div>
                                    </div>
                                  </div>
                                  <p>Linking Jane Account</p>
                                </div>
                                <div className="nameAddress1">
                                  <div className="loadersvg">
                                    <svg
                                      className="checkmark"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className="checkmark__circle"
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className="checkmark__check"
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>
                                    <div className="lineStatus"></div>
                                  </div>
                                  <p>Linking Jane Account</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="blockLine">
                            <div
                              className="thirdLoad"
                              style={{ display: "none" }}
                            >
                              <div className="loadingAnimate">
                                <div className="_name2">
                                  <div className="loadG">
                                    <div className="loaderGif">
                                      <img src="../assets/images/sandglass-loader.gif" />
                                      <div className="lineStatus"></div>
                                    </div>
                                  </div>

                                  <p>Importing Product Data...</p>
                                </div>

                                <div className="nameAddress2">
                                  <div className="loadersvg">
                                    <svg
                                      className="checkmark"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 52 52"
                                    >
                                      <circle
                                        className="checkmark__circle"
                                        cx="26"
                                        cy="26"
                                        r="25"
                                        fill="none"
                                      />
                                      <path
                                        className="checkmark__check"
                                        fill="none"
                                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                                      />
                                    </svg>
                                  </div>
                                  <p>Importing Product Data...</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="blockLine">
                          <div className="statusProj">
                            <div className="successFullBox">
                              <div
                                className="FourLoad"
                                style={{ display: "none" }}
                              >
                                Import Complete
                              </div>
                            </div>

                            <div
                              className="progress2 progress-moved"
                              style={{ display: "none" }}
                            >
                              <div className="progress-bar2"></div>
                            </div>

                            <Box
                              className="btnProgress"
                              style={{ display: "none" }}
                            >
                              <Button
                                onClick={nextPage}
                                className="active_btn_next"
                                variant="contained"
                              >
                                Next
                              </Button>
                            </Box>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <Box></Box>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Container>
        )}
      </div>
    </>
  );
};
export default AccountCreation;
