import Partner from './Partner';
import PartnerManager from '../utils/PartnerManager';

export interface ICategory {
    id: number;
    product_type: string;
    product_subtype: string;
}

export default class Category {
    private mId: number;
    public get id() {
      return this.mId;
    }
  
    private mName: string;
    public get name() {
      return this.mName;
    }

    private mRank: number;
    public get rank() {
      return this.mRank;
    }

    public set rank(num:number) {
      this.mRank=num;
    }

    private mProductsCount: number;
    public get productsCount() {
      return this.mProductsCount;
    }

    private mParentId: number;
    public get parentId() {
      return this.mParentId;
    }

    private mParentName: string;
    public get parentName() {
      return this.mParentName;
    }

    private mSubCategories: Category[];
    public get subCategories() {
      return this.mSubCategories;
    }

    public constructor() {}
    /*public constructor(responseJson: ICategory, parentId?: number) {
      this.mId = responseJson.id;
      if(!responseJson.product_subtype) {
        this.mName = responseJson.product_type;
        this.mParentId = null;
        this.mParentName = null;
      }else {
        this.mName = responseJson.product_subtype;
        this.mParentId = parentId;
        this.mParentName = responseJson.product_type;
      }
    }*/

    public init(responseJson: ICategory, parentId?: number) {
      this.mId = responseJson.id;
      if(!responseJson.product_subtype) {
        this.mName = responseJson.product_type;
        this.mParentId = null;
        this.mParentName = null;
        this.mProductsCount = 0;
      }else {
        this.mName = responseJson.product_subtype;
        this.mParentId = parentId;
        this.mParentName = responseJson.product_type;
        this.mProductsCount = 0;
      }
    }
    
    public saveSubCategories(subCategories: Category[]) {
        this.mSubCategories = subCategories;
    }

    public static list(arrayOfCategories: any): Category[] {
        let finalArray: Category[] = [];
        let rankIndex = 1;
        for (let category of arrayOfCategories) {
            let rootCategoryObj =  new Category();
            rootCategoryObj.init(category)
            rootCategoryObj.mRank = rankIndex;
            rankIndex += 1;
            if(!category.product_subtype) {
                // Root categories
                let subCatArray:  Category[] = [];
                let subCats = arrayOfCategories.filter((c: any) => c.product_type == rootCategoryObj.name && c.product_subtype != null);
                let rankIndexSub = 1;
                for (let subCategory of subCats) {
                  let subCategoryObj =  new Category();
                  subCategoryObj.init(subCategory,rootCategoryObj.id)
                  subCategoryObj.mRank = rankIndexSub;
                  subCatArray.push(subCategoryObj);
                  rankIndexSub += 1;
                }
                rootCategoryObj.saveSubCategories(subCatArray);
                finalArray.push(rootCategoryObj);
            } else {
                break;
            }
        }
        return finalArray;
    }

    public static algoliaList(response: any): Category[] {
      let finalArray: Category[] = [];
      let shouldApplySort = false
      if(response && 
        response.facets &&
        response.facets.root_types &&
        Object.keys(response.facets.root_types).length > 0) {
          const items = response.facets.root_types
          let rankIndex = 1
          Object.entries(items).forEach(
            ([key, value]) =>  {
              if(!key.includes(':')) {
                let categoryName = key
                let productCount = value as number
                let categoryObj =  new Category();
                categoryObj.mId = 0
                let partnerCategory = PartnerManager.shared().findCategory(categoryName);
                if(partnerCategory) {
                  categoryObj.mId = partnerCategory.id
                  categoryObj.mRank = partnerCategory.rank
                  if(!shouldApplySort) shouldApplySort = true
                } else {
                  categoryObj.mRank = rankIndex
                } 
                categoryObj.mName = categoryName
                categoryObj.mProductsCount = productCount;
                finalArray.push(categoryObj)
                rankIndex += 1
              }
            }
          );
      }
      if(shouldApplySort && finalArray.length > 0) {
        finalArray.sort((a, b) => (a.rank > b.rank ? 1 : -1))
      }
      return finalArray;
    }

    public static partnerCategoryList(arrayOfCategories: any): Category[] {
      let finalArray: Category[] = [];
      let id = 0
      for (let category of arrayOfCategories) {
        let rootCategoryObj =  new Category();
        rootCategoryObj.mId = (category.id)?category.id:id
        rootCategoryObj.mName = category.name
        rootCategoryObj.mRank = category.rank
        finalArray.push(rootCategoryObj);
        id++
      }
      return finalArray;
    }

}