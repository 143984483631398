import { NavLink } from "react-router-dom";

export default function SideBar(): JSX.Element {
  return (
    <div id="leftBar" className="leftBar">
      <NavLink to="/setup">
        <img src="../assets/images/side/setup.png" /> <p>Setup Guide</p>
      </NavLink>
      <NavLink to="/setup" className="navList">
        <img src="../assets/images/side/deals.png" /> <p>Deals</p>
      </NavLink>
      <NavLink to="/setup" className="navList">
        <img src="../assets/images/side/badges.png" />
        <p>Badges</p>
      </NavLink>
      <NavLink to="/setup" className="navList">
        <img src="../assets/images/side/gam.png" />
        <p>Gamification</p>
      </NavLink>
      <NavLink to="/setup" className="navList">
        <img src="../assets/images/side/wallet.png" /> <p>Wallet</p>
      </NavLink>
    </div>
  );
}
