import PartnerManager from '../utils/PartnerManager';

export default class Effect {
    private mId: number;
    public get id() {
      return this.mId;
    }

    private mName: string;
    public get name() {
      return this.mName;
    }

    private mRank: number;
    public get rank() {
      return this.mRank;
    }
    public set rank(num:number) {
      this.mRank=num;
    }
    private mProductsCount: number;
    public get productsCount() {
      return this.mProductsCount;
    }
  
    public constructor() {}

    public init(effectName: string, productsCount: any) {
      this.mName = effectName;
      this.mProductsCount = productsCount;
    }
    
    public static list(response: any): Effect[] {
        let finalArray: Effect[] = [];
        let shouldApplySort = false
        if(response && 
          response.facets &&
          response.facets.feelings &&
          Object.keys(response.facets.feelings).length > 0) {
            let items = response.facets.feelings
            let rankIndex = 1
            Object.entries(items).forEach(
              ([key, value]) =>  {
                let effectObj: Effect = new Effect()
                effectObj.init(key,value)
                effectObj.mId = 0
                let partnerEffect = PartnerManager.shared().findEffect(key);
                if(partnerEffect) {
                  effectObj.mId = partnerEffect.id
                  effectObj.mRank = partnerEffect.rank
                  if(!shouldApplySort) shouldApplySort = true
                } else {
                  effectObj.mRank = rankIndex
                }
                finalArray.push(effectObj)
                rankIndex += 1
              }
            );
        }
        if(shouldApplySort && finalArray.length > 0) {
          finalArray.sort((a, b) => (a.rank > b.rank ? 1 : -1))
        }
        return finalArray;
    }

    public static partnerEffectList(arrayOfEffects: any): Effect[] {
      let finalArray: Effect[] = [];
      let id = 0
      for (let effect of arrayOfEffects) {
        let effectObj: Effect = new Effect()
        effectObj.mId = (effect.id)?effect.id:id
        effectObj.mName = effect.name
        effectObj.mRank = effect.rank
        finalArray.push(effectObj);
        id++
      }
      return finalArray;
    }
}