import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Box, Typography, Button, Paper } from "@mui/material";
import { ApiError } from "../../core/webservice/ApiError";
import AuthService from "../../services/AuthService";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import ContextService from "../../services/ContextService";
import { IContextService } from "../../services/IContextService";
import HeartManage from "../HeartManage/HeartManage";
import { NoEncryption } from "@mui/icons-material";

interface LocationParams {
  pathname: string;
  state: string | any;
  search: string;
  hash: string;
  key: string;
}

const Otp = (): JSX.Element => {
  const context: IContextService = useContext(ContextService);
  const [OTP, setOTP] = useState<string>("");
  const navigate: NavigateFunction = useNavigate();
  const data: LocationParams = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingOTP, setLoadingOTP] = useState<boolean>(false);
  const [wrongOtp, setWrongOtp] = useState<boolean>(false);
  const [resendOtp, setResendOtp] = useState<boolean>(false);

  const moveCursorAtFirst = () => {
    document.getElementsByTagName("input")[0].focus();
  };

  const EmailsentBtn = (): void => {
    setLoadingOTP(true);
    setTimeout(() => {
      setLoadingOTP(false);
    }, 88888888888888888888888888000);

    setWrongOtp(false);
    setResendOtp(true);
    AuthService.sendAuthCodeByEmail(data.state)
      .then((isOtpSent: boolean) => {
        // console.log("otp sent successfully :" + isOtpSent);
      })
      .catch((error: ApiError) => {
        // console.log("error code:" + error.errorCode);
        // console.log("error message:" + error.message);
      });
  };
  const verifyOTP = async (otp?: any): Promise<void> => {
    setLoading(true);
    try {
      const obj = await AuthService.verifyAuthCodeByEmail(data.state, otp);
      if (obj.accessToken) {
        context.setAccessTokenValid(true);
        navigate("/accountCreation");
      }
    } catch (error) {
      setResendOtp(false);
      setWrongOtp(true);
      setOTP("");
      setLoading(false);
      moveCursorAtFirst();
    }
  };

  useEffect(() => {
    if (data.state === null) {
      navigate("/");
    }
  }, []);

  const otpSet = (otp: any): void => {
    setOTP(otp);
    if (otp.length > 3) {
      verifyOTP(otp);
    }
  };

  return (
    <>
      <Grid container className="fullHeight">
        <Grid md={6}>
          <Grid md={12}>
            <div>
              <img
                src="../assets/images/janeLogo.svg"
                className="logoResolution"
              />
            </div>
          </Grid>
          <Grid md={12}>
            <div className="containerWidthOtp">
              <Grid md={12}>
                <div className="welcome">Enter Code</div>
              </Grid>
              <Grid md={12}>
                <div className="checkEmail">
                  Check your email for a 4 digit code
                </div>
              </Grid>
              <Grid md={12}>
                <div className="otpWrapper">
                  <OtpInput
                    value={OTP}
                    onChange={(otp: any) => otpSet(otp)}
                    containerStyle={{}}
                    className={wrongOtp ? "otpbdr" : "otpcls"}
                    inputStyle={{
                      lineHeight: 2,
                      backgroundColor: "#FAF9FA",
                      border: "none",
                      width: 70,
                      height: 89,
                      margin: 10,
                      borderRadius: 5,
                      outline: "1px solid #000000",
                      fontSize: 24,
                    }}
                    isInputNum={true}
                    shouldAutoFocus={true}
                  />
                </div>
              </Grid>
              <Grid md={12}>
                <div className="resendWrapper">
                  Didn’t receive the code?
                  {!loadingOTP && (
                    <span className="resend" onClick={EmailsentBtn}>
                      Resend
                    </span>
                  )}
                </div>
              </Grid>
              <Grid md={12}>
                <div className="errorMessage">
                  {resendOtp && <div>code has been resend to your email</div>}
                  {wrongOtp && <div>Incorrect/expired Verification Code</div>}
                </div>
              </Grid>
              <Grid md={12}>
                <div className="buttoncontainer">
                  <Button
                    onClick={verifyOTP}
                    fullWidth
                    className={
                      !(OTP.length == 4)
                        ? "unactive_btn_loadingActive"
                        : "active_btn_loadingActive"
                    }
                    variant="contained"
                    disabled={!(OTP.length == 4)}
                  >
                    <p className="loadbtn">
                      {loading && "Please Wait"}
                      {!loading && " Continue"}
                      {loading && (
                        <i className="fa fa-circle-o-notch fa-spin"></i>
                      )}
                    </p>
                  </Button>
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid md={6}>
          <HeartManage />
        </Grid>
      </Grid>
    </>
  );
};
export default Otp;
