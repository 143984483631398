import TextUtils from './TextUtils';
import LocalStorageUtils from "./LocalStorageUtils";
import Partner, { PartnerState } from "../models/Partner";
import Category from '../models/Category';
import Store from "../models/Store";
import Effect from "../models/Effect";
import SessionManager from './SessionManager';
import AlgoliaManager from "../webservice/AlgoliaManager";
import { PartnerAppState } from '../models/Partner';
import PartnerTheme from '../models/PartnerTheme';

export default class PartnerManager {
  
  public static shared(): PartnerManager {
    if (!PartnerManager.sPartnerManager) {
        PartnerManager.sPartnerManager = new PartnerManager();
    }
    return PartnerManager.sPartnerManager;
  }

  private static sPartnerManager: PartnerManager | null;
  
  private constructor() { }

  public init() {
    this.mStores = []
    this.mCategories = []
    this.mEffects = []
  }

  // get partner
  private mPartner: Partner | undefined | null;
  public get partner() {
    return this.mPartner;
  }

  // set partner
  public setPartner(partner: Partner | undefined) {
    this.mPartner = partner
    AlgoliaManager.shared().initSearchClient(this.mPartner)
    const isSocialUpdated = (this.mPartner) ? this.mPartner.isAnySocialMediaInfoAvailable() : false
    const isBrandingUpdated = (this.mPartner && this.mPartner.theme && 
     this.partner.theme.favIconUrl && this.partner.theme.logoUrl ) ? true : false ;
    const isExternalDomainUpdated = (this.mPartner && this.mPartner.externalDomainName && !TextUtils.isEmpty(this.mPartner.externalDomainName)) ? true : false
    this.setIsSocialMediaUpdated(isSocialUpdated)
    this.setIsBrandingSetupDone(isBrandingUpdated)
    this.setIsConnectDomainCompleted(isExternalDomainUpdated)
  }

  // update partner
  public updatePartner(updatedPartner: Partner) {
    this.setPartner(updatedPartner)
  }

  // reset partner
  public resetPartner() {
    this.setPartner(undefined)
  }

  // get stores
  private mStores: Store[];
  public get stores() {
    return this.mStores;
  }

  // set stores
  public setStores(stores: Store[]) {
    this.mStores = stores
  }

  // reset stores
  public resetStores() {
    this.setStores([])
  }

  // get categories
  private mCategories: Category[];
  public get categories() {
    return this.mCategories;
  }

  // set categories
  public setCategories(categories: Category[]) {
    this.mCategories = categories
  }

  // reset categories
  public resetCategories() {
    this.setCategories([])
  }

  // get effects
  private mEffects: Effect[];
  public get effects() {
    return this.mEffects;
  }

  // set effects
  public setStoreEffects(effects: Effect[]) {
    this.mEffects = effects
  }

  // reset effects
  public resetEffects() {
    this.setStoreEffects([])
  }

  // update partner theme
  public updatePartnerTheme(theme: PartnerTheme) {
    if(this.partner) {
      this.partner.setTheme(theme)
    }
  }

  // update partner categories
  public updatePartnerCategories(categories: Category[]) {
    if(this.partner) {
      this.partner.setCategories(categories)
    }
  }

  // update partner categories
  public updatePartnerEffects(effects: Effect[]) {
    if(this.partner) {
      this.partner.setEffects(effects)
    }
  }

  // mIsBrandingSetupDone
  private mIsBrandingSetupDone: boolean;
  public get isBrandingSetupDone() {
    return this.mIsBrandingSetupDone;
  }

  // update social media details
  public setIsBrandingSetupDone(isUpdated: boolean) {
    if(!this.mIsBrandingSetupDone) {
      this.mIsBrandingSetupDone = isUpdated
    }
  }

  // mIsSocialMediaUpdated
  private mIsSocialMediaUpdated: boolean;
  public get isSocialMediaUpdated() {
    return this.mIsSocialMediaUpdated;
  }

  // update social media details
  public setIsSocialMediaUpdated(isUpdated: boolean) {
    if(!this.mIsSocialMediaUpdated) {
      this.mIsSocialMediaUpdated = isUpdated
    }
  }

  // mIsConnectDomainCompleted
  private mIsConnectDomainCompleted: boolean;
  public get isConnectDomainCompleted() {
    return this.mIsConnectDomainCompleted;
  }

  // update connect domain completed
  public setIsConnectDomainCompleted(isUpdated: boolean) {
    if(!this.mIsConnectDomainCompleted) {
      this.mIsConnectDomainCompleted = isUpdated
      if(this.partner) {
        this.partner.setupPartnerAppState()
      }
    }
  }

  public isJaneCredentialsAvailable(): boolean {
    let clientId = this.janeClientCredentials()[0]
    let clientSecret = this.janeClientCredentials()[1]
    if(!TextUtils.isEmpty(clientId) && !TextUtils.isEmpty(clientSecret)) {
      return true
    }
    return false
  }

  public janeClientCredentials(): [string, string] {
    let clientId = ''
    let clientSecret = ''
    if(this.partner) {
      clientId = this.partner.clientId
      clientSecret = this.partner.clientSecret
    }    
    return [clientId,clientSecret];
  }

  public doLogout() {
    this.resetPartner()
    this.resetStores()
    this.resetCategories()
    this.resetEffects()
    SessionManager.shared().clearSession()
    PartnerManager.sPartnerManager = undefined
    // console.log('logout successfully.')
  }

  // check partner website is live or not
  public isWebsiteLive() {
    if(this.partner && this.partner.partnerState === PartnerState.LIVE) {
      return true
    }
  }

  // find category by name
  public findCategory(name: string): Category | undefined {
    if(this.partner) {
      let partnerCategory = this.partner.categories.find((item: Category)=> item.name == name);
      if(partnerCategory) { 
        return partnerCategory
      }
    }
    return undefined
  }

  // find effect by name
  public findEffect(name: string): Effect | undefined {
    if(this.partner) {
      let partnerEffect = this.partner.effects.find((item: Effect)=> item.name == name);
      if(partnerEffect) { 
        return partnerEffect
      }
    }
    return undefined
  }

  // find store_ids associated with partner
  public storeIds(): number[] {
    const stores = this.stores
    if(stores && stores.length > 0) {
      const selectedIds = stores.map(({ id }) => id);
      // picked first store for now
      return [selectedIds[0]]
    }
    return []
  }

  public isAnySocialMediaInfoAvailable(): boolean {
    if(this.partner && (!TextUtils.isEmpty(this.partner.instagramUrl) || 
                      !TextUtils.isEmpty(this.partner.twitterUrl) ||
                      !TextUtils.isEmpty(this.partner.facebookUrl) ||
                      !TextUtils.isEmpty(this.partner.snapchatUrl) ||
                      !TextUtils.isEmpty(this.partner.youtubeUrl) ||
                      !TextUtils.isEmpty(this.partner.pinterestUrl))) {
        return true
    }
    return false
  }

  public getPartnerPreviewUrl(): string | undefined {
    let previewUrl = undefined
    if(this.partner && this.partner.domainName && this.partner.domainName.length > 0) {
      previewUrl = `https://preview-${this.partner.domainName}`
    }
    return previewUrl
  }

  public randomIntFromInterval(min: number, max: number) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  public getPartnerInternalDomainName(): string | undefined {
      let internalDomainName = undefined
      const stores = this.stores
      if(stores && stores.length > 0) {
        let shortStoreName = stores[0].name
        internalDomainName = this.getInternalDomainNameByStoreName(shortStoreName)
      }
      return internalDomainName    
  }

  public getProgressValueForSite(): {
                        progress: number, 
                        completedSteps: number, 
                        totalSteps: number, 
                        isWebsiteLive: boolean} {
    const stepsCount: number = 6
    const steps: number = 100 / stepsCount
    let totalSteps: number = 0
    let completedSteps: number = 0
    let isWebsiteLive: boolean = false
    if(!this.partner) {
      return { progress: (totalSteps | 0), completedSteps: completedSteps, totalSteps: stepsCount, isWebsiteLive: isWebsiteLive}
    }
    if(this.isJaneCredentialsAvailable() && !this.partner.isUserPoolDetailsEmpty()) {
      totalSteps += steps
      completedSteps += 1
    }
    if(this.partner.theme) {
      totalSteps += steps
      completedSteps += 1
    }
    if(this.isBrandingSetupDone) {
      totalSteps += steps
      completedSteps += 1
    }
    if(this.partner.categories.length > 0 || this.partner.effects.length > 0) {
      totalSteps += steps
      completedSteps += 1
    }
    if(this.isSocialMediaUpdated) {
      totalSteps += steps
      completedSteps += 1
    }
    if(this.partner.domainName && this.partner.domainName.length > 0 && 
      this.partner.externalDomainName && this.partner.externalDomainName.length > 0) {
      totalSteps += steps
      completedSteps += 1
    }
    if(this.partner.partnerAppState && 
      (this.partner.partnerAppState === PartnerAppState.SETUP_COMPLETED || 
      this.isWebsiteLive())) {
      totalSteps = 100
      completedSteps = 6
      isWebsiteLive = this.isWebsiteLive()
    }
    return { progress: (totalSteps | 0), completedSteps: completedSteps, totalSteps: stepsCount, isWebsiteLive: isWebsiteLive}
  }

  public isInternalDomainAvailable(): boolean {
    let isAvailable = false;
    const partner = this.partner;
    if(partner && partner.domainName && !TextUtils.isEmpty(partner.domainName)) {
      isAvailable = true
    }
    return isAvailable
  }

  public isExternalDomainAvailable(): boolean {
    let isAvailable = false;
    const partner = this.partner;
    if(partner && partner.externalDomainName && !TextUtils.isEmpty(partner.externalDomainName)) {
      isAvailable = true
    }
    return isAvailable
  }

  public getInternalDomainNameByStoreName(storeName: string): string | undefined {
    let internalDomainName = undefined
    if(storeName && storeName.length > 0) {
      let shortStoreName = storeName
      shortStoreName = shortStoreName.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");
      shortStoreName = shortStoreName.replace(/^\s+|\s+$/gm,''); // trim from left/right
      if(shortStoreName.length >= 6) {
        shortStoreName = shortStoreName.slice(0, 6);
      }
      // let randomNum: number = Math.floor(Math.random() * 100000 + 1);
      let randomNum: number = this.randomIntFromInterval(1000, 9999999)
      const rootDomain = process.env.REACT_APP_INTERNAL_ROOT_DOMAIN
      internalDomainName = `${shortStoreName}-${randomNum}.${rootDomain}`.toLowerCase();
    }
    return internalDomainName    
  }
}
