import Partner, { PartnerSocialMedia } from "../core/models/Partner";
import WebServiceUtils from "../core/webservice/WebServiceUtils";
import PartnerTheme, { PartnerAppTheme } from '../core/models/PartnerTheme';
import PartnerManager from "../core/utils/PartnerManager";
import Category from "../core/models/Category";
import Effect from "../core/models/Effect";
import TextUtils from '../core/utils/TextUtils';
import { PartnerState } from '../core/models/Partner';
import { ApiError } from "../core/webservice/ApiError";
import Strings from '../core/utils/Strings';

export default class PartnerService {

    private static graphqlApiUrl = process.env.REACT_APP_PARTNER_BACKEND_BASE_URL + '/graphql'
    private static fileApiUrl = process.env.REACT_APP_PARTNER_BACKEND_BASE_URL + '/files'
    private static config = {headers: {'Content-Type': 'application/json'}}
    
    /**
     * Get Partner Info.
     * @returns {Partner} - partner details object
    */
    public static async getPartnerInfo() {
        await WebServiceUtils.validatePartnerAuthToken();
        PartnerManager.shared().resetPartner()
        const response = await WebServiceUtils.post(
          this.getPartnerInfoBody(),
          PartnerService.config,
          PartnerService.graphqlApiUrl
        );
    
        if (
          response.success &&
          response.data &&
          response.data.data
        ) {
          try {
            if(response.data.data.partnerInfo) {
              const partner = new Partner(response.data.data.partnerInfo);
              PartnerManager.shared().setPartner(partner)
              return Promise.resolve(partner)
            } else {
              return Promise.resolve(null);
            }
          } catch (error) { }
        }
        return WebServiceUtils.handleNetworkError(response);
    }

    private static getPartnerInfoQueryParams(): any {
      return `id,clientId,clientSecret,domainName,externalDomainName,algoliaAppId,algoliaAppKey,instagramUrl,fbUrl,youTubUrl,twitterUrl,snapchatUrl,pinterestUrl,state,theme{id,name,logoUrl,faviconUrl,backgroundColor,pageHighlightColor,buttonColor,buttonTextColor},categories{id,name,rank},effects{id,name,rank},appSettings{appName},userPoolInfo{poolId,clientName,clientId},externalDomainStatus`
    }

    private static getPartnerInfoBody(): any {
        return `{
            "query": "query { partnerInfo{${this.getPartnerInfoQueryParams()}}}"
        }`.replace(/\s+/g, " ");
    }

    /**
     * Create partner.
     * @returns {Partner} - partner details object
    */
    public static async createPartner(clientId: string, clientSecretKey: string) {

      if(TextUtils.isEmpty(clientId) || TextUtils.isEmpty(clientSecretKey)) {
        return Promise.reject(
            new ApiError(400, Strings.MISSING_CLIENT_ID_ERROR)
        );
      }

      if(PartnerManager.shared().partner) {
        return Promise.reject(
          new ApiError(400, Strings.PARTNER_ALREADY_CREATED_ERROR)
        );
      }

      await WebServiceUtils.validatePartnerAuthToken();
      const response = await WebServiceUtils.post(
        this.getCreatePartnerBody(clientId,clientSecretKey),
        PartnerService.config,
        PartnerService.graphqlApiUrl
      );
  
      if (
        response.success &&
        response.data &&
        response.data.data &&
        response.data.data.createPartnerInfo
      ) {
        try {
          const partner = new Partner(response.data.data.createPartnerInfo);
          return Promise.resolve(partner);
        } catch (error) { }
      }
      return WebServiceUtils.handleNetworkError(response);
    }

    private static getCreatePartnerBody(clientId: string, clientSecretKey: string): any {
      return `{
        "query": "mutation($In:PartnerInfoCreate!) {createPartnerInfo(in:$In) {${this.getPartnerInfoQueryParams()}}}",
        "variables": {
            "In": {
                "clientId": "${clientId}",
                "clientSecret": "${clientSecretKey}"
            }
        }
      }`.replace(/\s+/g, " ");
    }

    /**
     * Save social media info.
     * @returns {Partner} - partner details object
    */
    public static async saveSocialMedia(partnerSocialMedia: PartnerSocialMedia) {
      await WebServiceUtils.validatePartnerAuthToken();
      const response = await WebServiceUtils.post(
        this.getSaveSocialMediaBody(partnerSocialMedia),
        PartnerService.config,
        PartnerService.graphqlApiUrl
      );
  
      if (
        response.success &&
        response.data &&
        response.data.data &&
        response.data.data.updatePartnerInfo
      ) {
        try {
          const partner = new Partner(response.data.data.updatePartnerInfo);
          PartnerManager.shared().updatePartner(partner);
          PartnerManager.shared().setIsSocialMediaUpdated(true);
          return Promise.resolve(partner);
        } catch (error) { }
      }
      return WebServiceUtils.handleNetworkError(response);
    }

    private static getSaveSocialMediaBody(partnerSocialMedia: PartnerSocialMedia): any {
      return `{
        "query": "mutation($In:PartnerInfoUpdate!) {updatePartnerInfo(in:$In) {${this.getPartnerInfoQueryParams()}}}",
        "variables": {
            "In": ${JSON.stringify(partnerSocialMedia)}
        }
      }`.replace(/\s+/g, " ");
    }

    /**
     * Create or Update Theme.
     * @returns {PartnerTheme} - theme object
    */
    public static async createOrUpdateTheme(partnerTheme: PartnerAppTheme) {
      await WebServiceUtils.validatePartnerAuthToken();
      const isUpdate = (partnerTheme.id) ? true: false
      let pTheme = partnerTheme
      if(isUpdate) {
        pTheme = {
          ...partnerTheme,
          themeId: partnerTheme.id
        }
        delete pTheme.name
        delete pTheme.id
      }
      const actionMethod = (isUpdate) ? 'updatePartnerTheme' : 'createPartnerTheme'
      const response = await WebServiceUtils.post(
        this.createOrUpdateThemeBody(pTheme),
        PartnerService.config,
        PartnerService.graphqlApiUrl
      );
  
      if (
        response.success &&
        response.data &&
        response.data.data &&
        response.data.data[actionMethod]
      ) {
        try {
          const theme  = new PartnerTheme(response.data.data[actionMethod])
          PartnerManager.shared().updatePartnerTheme(theme)
          return Promise.resolve(theme);
        } catch (error) { }
      }
      return WebServiceUtils.handleNetworkError(response);
    }

    private static createOrUpdateThemeBody(partnerTheme: PartnerAppTheme): any {
      const isUpdate = (partnerTheme.themeId) ? true: false
      if(isUpdate) {
        return `{
          "query": "mutation($In:ThemeUpdate!) {updatePartnerTheme(in:$In) {id,name,logoUrl,faviconUrl,backgroundColor,pageHighlightColor,buttonColor,buttonTextColor}}",
          "variables": {
              "In": ${JSON.stringify(partnerTheme)}
          }
        }`.replace(/\s+/g, " ");
      }

      return `{
          "query": "mutation($In:ThemeCreate!) {createPartnerTheme(in:$In) {id,name,logoUrl,faviconUrl,backgroundColor,pageHighlightColor,buttonColor,buttonTextColor}}",
          "variables": {
              "In": ${JSON.stringify(partnerTheme)}
          }
      }`.replace(/\s+/g, " ");
    }

    /**
     * Save categories.
     * @returns {boolean} - is saved or not
    */
    public static async saveCategories(categories: Category[]) {
        await WebServiceUtils.validatePartnerAuthToken();
        const response = await WebServiceUtils.post(
          this.getSaveCategoriesBody(categories),
          PartnerService.config,
          PartnerService.graphqlApiUrl
        );

        if (
          response.success &&
          response.data &&
          response.data.data &&
          response.data.data.saveUserSelectedCategories
        ) {
          try {
            const reqCategoriesList = this.getRequestCategoriesList(categories)
            const partnerCategories = Category.partnerCategoryList(reqCategoriesList)
            PartnerManager.shared().updatePartnerCategories(partnerCategories)
            return Promise.resolve(response.data.data.saveUserSelectedCategories);
          } catch (error) { }
        }
        return WebServiceUtils.handleNetworkError(response);
    }

    public static getRequestCategoriesList(categories: Category[]): any[] {
      var arrOfCategories = categories
      arrOfCategories.sort((a, b) => (a.rank > b.rank ? 1 : -1))
      const newArrayOfC = arrOfCategories.map(((category) => ({'name': category.name, 'rank': category.rank})));
      return newArrayOfC
    }

    private static getSaveCategoriesBody(categories: Category[]): any {
      var arrOfCategories = categories
      arrOfCategories.sort((a, b) => (a.rank > b.rank ? 1 : -1))
      const newArrayOfC = arrOfCategories.map(((category) => ({'name': category.name, 'rank': category.rank})));
      return `{
        "query": "mutation($In:[CategoryCreate!]!) {saveUserSelectedCategories(in:$In)}",
        "variables": {
            "In": ${JSON.stringify(newArrayOfC)}
        }
      }`.replace(/\s+/g, " ");
    }

    /**
     * Save effects.
     * @returns {boolean} - is saved or not
    */
     public static async saveEffects(effects: Effect[]) {
      await WebServiceUtils.validatePartnerAuthToken();
      const response = await WebServiceUtils.post(
        this.getSaveEffectsBody(effects),
        PartnerService.config,
        PartnerService.graphqlApiUrl
      );

      if (
        response.success &&
        response.data &&
        response.data.data &&
        response.data.data.saveUserSelectedEffects
      ) {
        try {
          const reqEffectsList = this.getRequestEffectsList(effects)
          const partnerEffects = Effect.partnerEffectList(reqEffectsList)
          PartnerManager.shared().updatePartnerEffects(partnerEffects)
          return Promise.resolve(response.data.data.saveUserSelectedEffects);
        } catch (error) { }
      }
      return WebServiceUtils.handleNetworkError(response);
  }

  public static getRequestEffectsList(effects: Effect[]): any[] {
    var arrOfEffects = effects
    arrOfEffects.sort((a, b) => (a.rank > b.rank ? 1 : -1))
    const newArrayOfE = arrOfEffects.map(((effect) => ({'name': effect.name, 'rank': effect.rank})));
    return newArrayOfE
  }

  private static getSaveEffectsBody(effects: Effect[]): any {
    var arrOfEffects = effects
    arrOfEffects.sort((a, b) => (a.rank > b.rank ? 1 : -1))
    const newArrayOfE = arrOfEffects.map(((effect) => ({'name': effect.name, 'rank': effect.rank})));
    return `{
      "query": "mutation($In:[EffectCreate!]!) {saveUserSelectedEffects(in:$In)}",
      "variables": {
          "In": ${JSON.stringify(newArrayOfE)}
      }
    }`.replace(/\s+/g, " ");
  }

  public static saveInternalDomainIfRequired() {

    const partner = PartnerManager.shared().partner
    const isStoreAvailable = (partner && PartnerManager.shared().stores && PartnerManager.shared().stores.length > 0) ? true : false
    if(partner && isStoreAvailable && !PartnerManager.shared().isInternalDomainAvailable()){
       const internalDomainName=PartnerManager.shared().getPartnerInternalDomainName();
       PartnerService.saveDomain(internalDomainName)
       .then((result: Partner) => {
        //  console.log("saveDomain domainName :" + result.domainName);
       })
       .catch((error: ApiError) => {
        //  console.log("error code:" + error.errorCode);
        //  console.log("error message:" + error.message);
       });
     }
  }

    /**
     * Save domain name.
     * @returns {Partner} - partner details object
    */
   public static async saveDomain(domainName: string) {
    await WebServiceUtils.validatePartnerAuthToken();
    const response = await WebServiceUtils.post(
      this.saveDomainBody(domainName),
      PartnerService.config,
      PartnerService.graphqlApiUrl
    );

    if (
      response.success &&
      response.data &&
      response.data.data &&
      response.data.data.updatePartnerInfo
    ) {
      try {
        const partner = new Partner(response.data.data.updatePartnerInfo);
        PartnerManager.shared().updatePartner(partner);
        return Promise.resolve(partner);
      } catch (error) { }
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  private static saveDomainBody(domainName: string): any {
    return `{
      "query": "mutation($In:PartnerInfoUpdate!) {updatePartnerInfo(in:$In) {${this.getPartnerInfoQueryParams()}}}",
      "variables": {
          "In": {
            "domainName":"${TextUtils.trimString(domainName)}"
          }
      }
    }`.replace(/\s+/g, " ");
  }

  /**
     * Save external domain name.
     * @returns {Partner} - partner details object
    */
   public static async saveExternalDomain(externalDomainName: string) {
    await WebServiceUtils.validatePartnerAuthToken();
    const response = await WebServiceUtils.post(
      this.saveExternalDomainBody(externalDomainName),
      PartnerService.config,
      PartnerService.graphqlApiUrl
    );

    if (
      response.success &&
      response.data &&
      response.data.data &&
      response.data.data.updatePartnerInfo
    ) {
      try {
        const partner = new Partner(response.data.data.updatePartnerInfo);
        PartnerManager.shared().updatePartner(partner);
        return Promise.resolve(partner);
      } catch (error) { }
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  private static saveExternalDomainBody(externalDomainName: string): any {
    return `{
      "query": "mutation($In:PartnerInfoUpdate!) {updatePartnerInfo(in:$In) {${this.getPartnerInfoQueryParams()}}}",
      "variables": {
          "In": {
            "externalDomainName":"${TextUtils.trimString(externalDomainName)}",
            "externalDomainStatus":"PENDING"
          }
      }
    }`.replace(/\s+/g, " ");
  }

  /**
     * Go Live website.
     * @returns {Partner} - partner details object
  */
  public static async makeGoLive() {
    await WebServiceUtils.validatePartnerAuthToken();
    const response = await WebServiceUtils.post(
      this.makeGoLiveBody(),
      PartnerService.config,
      PartnerService.graphqlApiUrl
    );

    if (
      response.success &&
      response.data &&
      response.data.data &&
      response.data.data.partnerGoLive
    ) {
      try {
        const partner = new Partner(response.data.data.partnerGoLive);
        PartnerManager.shared().updatePartner(partner);
        return Promise.resolve(partner);
      } catch (error) { }
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  private static makeGoLiveBody(): any {
    return `{
      "query": "mutation{partnerGoLive {${this.getPartnerInfoQueryParams()}}}"
    }`.replace(/\s+/g, " ");
  }

  /**
     * Upload file.
     * @returns {string} - file id of uploaded document
  */
   public static async uploadFile(file: any) {

    var fileName = file.name;
    var allowedExtensions = /(\.jpeg|\.jpg|\.JPG|\.JPEG|\.gif|\.GIF|\.png|\.PNG|\.ico|\.ICO)$/;
    if (fileName != "" && !allowedExtensions.exec(fileName)) {
      return Promise.reject(
        new ApiError(400, Strings.INVALID_FILE_ERROR)
      );
    }

    await WebServiceUtils.validatePartnerAuthToken();
    const response = await WebServiceUtils.multipartPost(
      file,
      PartnerService.fileApiUrl
    );

    if (
      response.success &&
      response.data &&
      Array.isArray(response.data) &&
      response.data.length > 0
    ) {
      try {
        const fileObj = response.data[0]
        const fileId = fileObj.file
        return Promise.resolve(fileId);
      } catch (error) { }
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  /**
     * Save App name.
     * @returns {Partner} - partner details object
    */
   public static async saveAppName(appName: string) {
    await WebServiceUtils.validatePartnerAuthToken();
    const response = await WebServiceUtils.post(
      this.saveAppNameBody(appName),
      PartnerService.config,
      PartnerService.graphqlApiUrl
    );

    if (
      response.success &&
      response.data &&
      response.data.data &&
      response.data.data.updatePartnerInfo
    ) {
      try {
        const partner = new Partner(response.data.data.updatePartnerInfo);
        PartnerManager.shared().updatePartner(partner);
        return Promise.resolve(partner);
      } catch (error) { }
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  private static saveAppNameBody(appName: string): any {
    return `{
      "query": "mutation($In:PartnerInfoUpdate!) {updatePartnerInfo(in:$In) {${this.getPartnerInfoQueryParams()}}}",
      "variables": {
          "In": {
            "appSettings":{
              "appName":"${TextUtils.trimString(appName)}"
            }
          }
      }
    }`.replace(/\s+/g, " ");
  }

  /**
     * Save UserPool info.
     * @returns {Partner} - partner details object
    */
   public static async saveUserPoolDetails(region: string,userPoolId: string,appClientId: string) {
    await WebServiceUtils.validatePartnerAuthToken();

    if(TextUtils.isEmpty(region) || TextUtils.isEmpty(userPoolId) || TextUtils.isEmpty(appClientId)) {
      return Promise.reject(
          new ApiError(400, Strings.REQUEST_PARAMS_MISSING)
      );
    }

    const userPoolInfo = {
      userPoolInfo: {
        poolId: region, 
        clientName: userPoolId, 
        clientId: appClientId
      }
    }
    const response = await WebServiceUtils.post(
      this.saveUserPoolBody(userPoolInfo),
      PartnerService.config,
      PartnerService.graphqlApiUrl
    );

    if (
      response.success &&
      response.data &&
      response.data.data &&
      response.data.data.updatePartnerInfo
    ) {
      try {
        const partner = new Partner(response.data.data.updatePartnerInfo);
        PartnerManager.shared().updatePartner(partner);
        return Promise.resolve(partner);
      } catch (error) { }
    }
    return WebServiceUtils.handleNetworkError(response);
  }

  private static saveUserPoolBody(userPoolInfo: any): any {
    return `{
      "query": "mutation($In:PartnerInfoUpdate!) {updatePartnerInfo(in:$In) {${this.getPartnerInfoQueryParams()}}}",
      "variables": {
          "In": ${JSON.stringify(userPoolInfo)}
      }
    }`.replace(/\s+/g, " ");
  }

}