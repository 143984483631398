import {
    Button,
    Box,
    Grid,
    TextField,
    Typography,
    Divider,
  } from "@mui/material";
import { styled } from "@mui/material/styles";
const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#d7d2da",
    },
  
    "& .MuiInput-underline:after": {
      borderBottomColor: "#d7d2da",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d7d2da",
      },
      "&:hover fieldset": {
        borderColor: "#d7d2da",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#d7d2da",
      },
    },
  });

  interface IProps {
    appName: string,
    errorAppName: string,
    onChangeAppName(name: string): void
  }

const YourAppName = (props: IProps): JSX.Element => {
    return (
     
       <Grid container>
              <Grid textAlign="left" item md={4}>
                <Box className="logo_Url">
                <CssTextField
                  hiddenLabel
                  className="socialLink"
                  id="outlined-helperText"
                  fullWidth
                  placeholder="Your App/Display Name"
                  value={props.appName}
                  onChange={(e) => {
                    props.onChangeAppName(e.target.value)
                  }}
                  helperText="Choose a name for your app that is unique, simple and accurately describes it. App name should be limited to 20 characters"
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
                </Box>
              </Grid>
              {props.errorAppName && <Typography variant="subtitle1" sx={{color:'red',width: "100%"}}>
                {props.errorAppName}
              </Typography>}
              <Divider
              style={{ width: "100%" }}
              className="divider divider_brand"
              sx={{ mt: 6, mb: 1 }}
            />
</Grid>
    
     
    );
};

export default YourAppName;