import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
interface IProps {
  buttonColor: string;
  setButtonColor: React.Dispatch<React.SetStateAction<string>>;
}
const ButtonTextBg = (props: IProps) => {
  const { buttonColor, setButtonColor } = props;
  return (
    <Box className="colorPicker2">
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <input
            className="inputBoxButtonText"
            id="outlined-helperText"
            defaultValue="Logo URL"
            value={buttonColor}
            type="color"
            onChange={(e) => setButtonColor(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={6} lg={4}>
          <Box>
            <div style={{ marginTop: "20px" }}>
              <input
                className="inputMargin"
                placeholder={buttonColor}
                onChange={(e) => setButtonColor(e.target.value)}
                type="text"
                value={buttonColor}
              />
              <Box className="hex">Hex Color (3 or 6 digits)</Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ButtonTextBg;
//   <Box className='colorPicker2'>
// <Grid container xs={12} spacing={2}>
// <Grid item xs={12} sm={4} md={4} lg={3}>
// <input className='inputBoxButtonText'
//   id="outlined-helperText"
//   defaultValue="Logo URL"

//   value={buttonColor} type="color" onChange={(e) => setButtonColor(e.target.value)} />
//       </Grid>

//       <Grid item xs={12} sm={4} md={6} lg={4}>
//         <Box className="BtnGroupBrand">

//         <Box className="colorPickerTxt">{buttonColor}</Box>
//         <Divider style={{backgroundColor:"#000"}} />
//         <Box className="hex">Hex Color (3 or 6 digits)</Box>
//         </Box>

//     </Grid>
//   </Grid>
//   </Box>
