import { Grid, Box, TextField, Divider } from "@mui/material";
import React, { useState } from "react";

interface IProps {
  textColor: string;
  setTextColor: React.Dispatch<React.SetStateAction<string>>;
}

const PageBgText = (props: IProps) => {
  const { textColor, setTextColor } = props;
  return (
    <Box className="colorPicker2">
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <input
            type="color"
            value={textColor}
            onChange={(e) => setTextColor(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={6} lg={4}>
          <Box>
            <div style={{ marginTop: "20px" }}>
              <input
                className="inputMargin"
                placeholder={textColor}
                value={textColor}
                onChange={(e) => setTextColor(e.target.value)}
                type="text"
              />
              <Box className="hex">Hex Color (3 or 6 digits)</Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default PageBgText;
// <Box className="colorPicker2">
//   <Grid container xs={12} spacing={2}>
//     <Grid item xs={12} sm={4} md={4} lg={3}>
// <input
//   type="color"
//   value={textColor}
//   onChange={(e) => setTextColor(e.target.value)}
// />
//     </Grid>

//     <Grid item xs={12} sm={4} md={6} lg={4}>
//       <Box className="BtnGroupBrand">
//         <Box className="colorPickerTxt"> {textColor}</Box>

//         <Divider style={{ backgroundColor: "#000" }} />
//         <Box className="hex">Hex Color (3 or 6 digits)</Box>
//       </Box>
//     </Grid>
//   </Grid>
// </Box>
