import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
interface IProps {
  buttonTextColor: string;
  setButtonTextColor: React.Dispatch<React.SetStateAction<string>>;
}
const ButtonText = (props: IProps) => {
  const { buttonTextColor, setButtonTextColor } = props;
  return (
    <Box className="colorPicker2">
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <input
            className="inputBoxButtonText"
            defaultValue="Logo URL"
            value={buttonTextColor}
            type="color"
            onChange={(e) => setButtonTextColor(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={6} lg={4}>
          <Box>
            <div style={{ marginTop: "20px" }}>
              <input
                className="inputMargin"
                placeholder={buttonTextColor}
                onChange={(e) => setButtonTextColor(e.target.value)}
                type="text"
                value={buttonTextColor}
              />
              <Box className="hex">Hex Color (3 or 6 digits)</Box>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ButtonText;
// <Box className="colorPicker2">
//   <Grid container xs={12} spacing={2}>
//     <Grid item xs={12} sm={4} md={4} lg={3}>
// <input
//   className="inputBoxButtonText"
//   defaultValue="Logo URL"
//   value={buttonTextColor}
//   type="color"
//   onChange={(e) => setButtonTextColor(e.target.value)}
// />
//     </Grid>

//     <Grid item xs={12} sm={4} md={6} lg={4}>

//       <Box className="BtnGroupBrand">
//       <Box className="colorPickerTxt">{buttonTextColor}</Box>
//       <Divider style={{backgroundColor:"#000"}} />
//       <Box className="hex">Hex Color (3 or 6 digits)</Box>
//       </Box>

//     </Grid>
//   </Grid>
// </Box>
