import React, { useEffect, useState } from "react";
import SideBar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import LinkJaneAccount from "./Accordion/LinkJaneAccount";
import ChooseTheme from "./Accordion/ChooseTheme";
import SocialMediaAccount from "./Accordion/SocialMediaAccount";
import SetupBrandLogo from "./Accordion/SetupBrandLogo";
import OrganiseCategories from "./Accordion/OrganiseCategories";
import ConnectDomain from "./Accordion/ConnectDomain";
import SiteStatus from "./SiteStatus/SiteStatus";
import OrganiseEffects from "./Accordion/OrganiseEffects";
import PartnerManager from "../../core/utils/PartnerManager";
import { Box } from "@mui/system";
import PartnerTheme from "../../core/models/PartnerTheme";
import { SetupGuide } from "./SetupGuide/SetupGuide";
import AlertBox from "../alertBox/AlertBox";

const AccountSetup = (): JSX.Element => {
  const sPartner = PartnerManager.shared().partner;
  const sThemesList = PartnerTheme.themesList();
  const sActiveTheme =
    sPartner && sPartner.theme ? sPartner.theme : sThemesList[0];

  const [themesList, setThemesList] = useState<PartnerTheme[]>(sThemesList);
  const [activeTheme, setActiveTheme] = useState<PartnerTheme>(sActiveTheme);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleThemeUpdated = (updatedTheme: PartnerTheme) => {
    setActiveTheme(updatedTheme);
  };

  return (
    <>
      <Header /> {/*Header*/}
      <SideBar /> {/*SideBar*/}
      {sPartner && (
        <div className="contentBox">
          <SetupGuide /> {/* 0. Setup Guide with progress */}
          {/* 1. Link Jane Account */}
          <LinkJaneAccount onConfirmClicked={() => {}} />
          {/*2. Choose Theme */}
          <ChooseTheme
            pThemesList={themesList}
            pActiveTheme={activeTheme}
            onThemeUpdated={handleThemeUpdated}
          />
          {/*3. Setup Branding & Logo */}
          <SetupBrandLogo
            pActiveTheme={activeTheme}
            onBrandingAndLogoUpdated={handleThemeUpdated}
          />
          {/*4 . Organise Categories */}
          {activeTheme.isCategoryBased() && (
            <OrganiseCategories pActiveTheme={activeTheme} />
          )}
          {/*4 . Organise Effects */}
          {activeTheme.isEffectBased() && (
            <OrganiseEffects pActiveTheme={activeTheme} />
          )}
          <SocialMediaAccount /> {/* 5 . social media */}
          <ConnectDomain /> {/* 6 . Connect Domain */}
          {/* <AlertBox/> */}
          <SiteStatus />
          {/* 7 . Site Status */}
        </div>
      )}
    </>
  );
};

export default AccountSetup;
