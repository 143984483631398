import React, { useState } from "react";
import Loader from "../../../commonComponents/Loader";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Tab,
  AppBar,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";

interface IProps {
  previewEffect: boolean;
  setPreviewEffect: React.Dispatch<React.SetStateAction<boolean>>;
  previewValue: {
    uiLevel: string;
    backEndLevel: string;
  };
  setpreviewValue: React.Dispatch<
    React.SetStateAction<{
      uiLevel: string;
      backEndLevel: string;
    }>
  >;
}

export default function Effect1Themes(props: IProps): JSX.Element {
  const [showPage1, setShowPage1] = useState(true);
  const [showPage2, setShowPage2] = useState(false);
  const [showPage3, setShowPage3] = useState(false);
  const [loading, setLoading] = useState(true);

  const showPageEvnt1 = () => {
    setShowPage1(true);
    setShowPage2(false);
    setShowPage3(false);
    setLoading(true);
  };
  const showPageEvnt2 = () => {
    setShowPage1(false);
    setShowPage2(true);
    setShowPage3(false);
    setLoading(true);
  };
  const showPageEvnt3 = () => {
    setShowPage1(false);
    setShowPage2(false);
    setShowPage3(true);
    setLoading(true);
  };

  const [value, setValue] = React.useState("2");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const closeHandler = () => {
    props.setPreviewEffect(false);
    // props.setpreviewValue("");
    props.setpreviewValue({ uiLevel: "", backEndLevel: "" });
    setTimeout(()=>{
      setShowPage1(true);
      setShowPage2(false);
      setShowPage3(false);
    },1000)
  };

  const StopLoader = () => { setLoading(false); }

  return (
    <div>
      <Dialog className="previewThemes" open={props.previewEffect}>
        <AppBar position="static">
          <Box className="previewThemes_header">
            <header>
              <Box className="previewMode">
                Preview Mode :{" "}
                <span style={{ color: "#000", fontWeight: "700" }}>
                  {props.previewValue.uiLevel}
                </span>{" "}
                <span style={{ color: "#000", fontWeight: "bold" }}></span>{" "}
              </Box>
              <DialogActions>
                <Button onClick={closeHandler}>
                  Close preview <CloseIcon />
                </Button>
              </DialogActions>
            </header>
          </Box>
        </AppBar>

        <Box className="SideTab">
          <Box className="previewThemes_tabs">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  textColor="secondary"
                  TabIndicatorProps={{ style: { background: "black" } }}
                >
                  <Tab label="Pages" value="2" />
                </TabList>
              </Box>

              <TabPanel value="2">
                <Box className="listPage">
                  <Link
                    component="button"
                    variant="body2"
                    className={showPage1 && "active"}
                    onClick={showPageEvnt1}
                  >
                    <img src="../assets/images/home.png" />{" "}
                    <Typography>Home</Typography>
                  </Link>
                </Box>
                <Box className="listPage">
                  <Link
                    component="button"
                    variant="body2"
                    className={showPage2 && "active"}
                    onClick={showPageEvnt2}
                  >
                    <img src="../assets/images/listing.png" />
                    <Typography>Product Listing</Typography>
                  </Link>
                </Box>

                <Box className="listPage">
                  <Link
                    component="button"
                    variant="body2"
                    className={showPage3 && "active"}
                    onClick={showPageEvnt3}
                  >
                    <img src="../assets/images/details.png" />{" "}
                    <Typography> Product Details</Typography>
                  </Link>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>

        <Box className="Themescontainer">

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Grid item xs={12} sm={6} lg={3}>
                <Box className="loadrPart">
                  {/* here */}

                  {loading && <Loader />}
                  {showPage1 &&
                    value == "2" &&
                    (props.previewValue.backEndLevel == "EFFECTS" ? (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/effect2.svg" onLoad={StopLoader} />

                      </Box>
                    ) : props.previewValue.backEndLevel == "EFFECTS_V2" ? (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/effect_1.png" onLoad={StopLoader} />
                      </Box>
                    ) : (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/categories.png" onLoad={StopLoader}
                        />
                      </Box>
                    ))}

                  {showPage2 &&
                    value == "2" &&
                    (props.previewValue.backEndLevel == "EFFECTS" ? (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/product_list.svg" onLoad={StopLoader}
                        />
                      </Box>
                    ) : props.previewValue.backEndLevel == "EFFECTS_V2" ? (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/categories_prod_list_Effect.png" onLoad={StopLoader} />
                      </Box>
                    ) : (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/categories_prod_list.png" onLoad={StopLoader} />
                      </Box>
                    ))}

                  {showPage3 &&
                    value == "2" &&
                    (props.previewValue.backEndLevel == "EFFECTS" ? (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/product_details.svg" onLoad={StopLoader} />
                      </Box>
                    ) : props.previewValue.backEndLevel == "EFFECTS_V2" ? (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/effect2_pdp.png" onLoad={StopLoader} />
                      </Box>
                    ) : (
                      <Box className="prevImg_show">
                        <img src="../assets/images/themes/category_pdp.png" onLoad={StopLoader} />
                      </Box>
                    ))}
                  {/* here */}
                </Box>
              </Grid>
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
