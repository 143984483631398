import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import PartnerManager from "../../../core/utils/PartnerManager";
import PartnerService from "../../../services/PartnerService";
import Partner, { ExternalDomainStatus } from "../../../core/models/Partner";
import { ApiError } from "../../../core/webservice/ApiError";
import { IContextService } from "../../../services/IContextService";
import ContextService from "../../../services/ContextService";
import { styled } from "@mui/material/styles";
import GeneralUtils from "../../../core/utils/GeneralUtils";
import Strings from '../../../core/utils/Strings';
import SeeDetailsDomain from "./SeeDetailsDomain/SeeDetailsDomain";

const ConnectDomain = (): JSX.Element => {
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#d7d2da",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#d7d2da",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#d7d2da",
      },
      "&:hover fieldset": {
        borderColor: "#d7d2da",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#d7d2da",
      },
    },
  });

  const [enterDomain, setEnterDomain] = useState<string>("");
  const [open, setOpen] = React.useState<boolean>(true);
  const context: IContextService = useContext(ContextService);
  const [tickIcon, setTickIcon] = useState<boolean>(false);
  const [internalDomainName, setInternalDomainName] =
    useState<string>(undefined);

  useEffect(() => {
    if (PartnerManager.shared().isInternalDomainAvailable()) {
      setInternalDomainName(PartnerManager.shared().partner.domainName);
    }
    if (!PartnerManager.shared().isExternalDomainAvailable()) {
      setTickIcon(false);
    } else {
      PartnerManager.shared().setIsConnectDomainCompleted(true);
      setTickIcon(true);
    }
    /*
    const { domainName } = PartnerManager.shared().partner;
    if (!domainName || (domainName && domainName.length == 0)) {
      setTickIcon(false);
      setInternalDomainName(
        PartnerManager.shared().getPartnerInternalDomainName()
      );
    } else {
      setTickIcon(true);
      setInternalDomainName(domainName);
    }*/
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      context.setPanel(newExpanded ? panel : false);
    };
  const skipDomain = (): void => {
    // context.setHeader({ length: 100, index: 6 });
    // setTickIcon(true);
    context.setPanel("panel7");
  };

  const connectExternalDomain = (): void => {
    if (PartnerManager.shared().isExternalDomainAvailable()) {
      setTickIcon(true);
      context.setPanel("panel7");
      return;
    }

    const externalDomainName = GeneralUtils.validHostName(enterDomain);
    if (!externalDomainName) {
      // display url
      return;
    }
    PartnerService.saveExternalDomain(externalDomainName)
      .then((result: Partner) => {
        if (result) {
          setTickIcon(true);
          context.setPanel("panel7");
          // context.setHeader({ length: 100, index: 6 });
        }
      })
      .catch((error: ApiError) => {
        // console.log("error code:" + error.errorCode);
        // console.log("error message:" + error.message);
      });
  };

  const nextButtonClicked = (): void => {
    PartnerManager.shared().setIsConnectDomainCompleted(true);
    setTickIcon(true);
    context.setPanel("panel7");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEnterDomain(value ? value.toLowerCase() : "");
  };

  const renderLinkedExternalDomain = () => {
    const isExternalDomainLinked =
      PartnerManager.shared().isExternalDomainAvailable();
    const externalDomainName = isExternalDomainLinked
      ? PartnerManager.shared().partner.externalDomainName
      : "";
    return (
      <Box>
        <Typography
          variant="h6"
          sx={{ mb: isExternalDomainLinked ? 2 : 0 }}
          className="connectLbl"
        >
          Your Custom Domain
        </Typography>
        {isExternalDomainLinked ? (
          <Typography className="connectSub">
            <a href={`https://${externalDomainName}`} target="_blank">
              {externalDomainName}
            </a>
          </Typography>
        ) : (
          <Grid container className="domainlbl" spacing={3}>
            <Grid item md={4}>
              <TextField
                label=""
                placeholder="Your Domain"
                variant="standard"
                value={enterDomain}
                onChange={handleInputChange}
                focused
              />
              {/* <CssTextField
              id="standard-basic"
              placeholder="Your Domain"
              variant="standard"
              value={enterDomain}
              onChange={handleInputChange}
              className="domainInput"
            /> */}
              <p className="tagdomain">
                Your private domain for your store. Example: yourcompanyname.com
              </p>
            </Grid>
          </Grid>
        )}
      </Box>
    );
  };

  const connectBtnTitle = () => {
    let btnTitle = 'Connect'
    const isExternalDomainLinked = PartnerManager.shared().isExternalDomainAvailable();
    if(isExternalDomainLinked && PartnerManager.shared().partner.externalDomainStatus === ExternalDomainStatus.PENDING) {
      btnTitle = 'Connection - Pending'
    } else if(isExternalDomainLinked && PartnerManager.shared().partner.externalDomainStatus === ExternalDomainStatus.CONNECTED) {
      btnTitle = 'Connected'
    }
    return btnTitle
  }

  const renderConnectButton = () => {
    const isExternalDomainLinked =
      PartnerManager.shared().isExternalDomainAvailable();
    return (
      <Button
        className="setup_btn account-setup setupConnect"
        sx={{ mt: 5 }}
        onClick={connectExternalDomain}
        disabled={isExternalDomainLinked}
        variant="contained"
      >
        {`${connectBtnTitle()}`}
      </Button>
    );
  };

  return (
    <>
      <Accordion
        className="accordion_border border_none"
        expanded={context.panel === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          sx={{ mt: 2 }}
          expandIcon={<AddIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          className="reduce_height"
        >
          <Typography className="acc_header">
            {tickIcon && (
              <img
                src="../assets/images/main/check.png"
                className="iconCheck"
              />
            )}
            {Strings.CONNECT_DOMAIN}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            item
            md={10}
            spacing={0}
            style={{ paddingLeft: tickIcon && "20px" }}
          >
            <Grid className="alertBox" sx={{ mt: 2, mb: 4 }} item md={6}>
              <Typography>
                If you have a custom domain for your store, enter it below. If
                you don’t have one, you can still launch your store on our
                domain.
              </Typography>
            </Grid>
          </Grid>
          <div style={{ paddingLeft: "20px" }}>
            <Box sx={{ mb: 5 }}>
              <Typography sx={{ mb: 2 }} variant="h6" className="connectLbl">
                System Generated Domain
              </Typography>

              {PartnerManager.shared().partner.domainName ? (
                <Typography className="connectSub">
                  <a href={`https://${internalDomainName}`} target="_blank">
                    {internalDomainName}
                  </a>
                </Typography>
              ) : (
                <Typography className="connectSub">
                  {internalDomainName}
                  <img src="../assets/images/main/write.png" />
                  <Divider
                    style={{
                      width: "20%",
                      backgroundColor: "rgb(176 176 176 / 42%)",
                    }}
                    className="divider"
                    sx={{ mt: 2, mb: 2 }}
                  />
                </Typography>
              )}
            </Box>
            {renderLinkedExternalDomain()}
            {/* <Typography variant="h6" className="connectLbl">
              Your Custom Domain
            </Typography>
            <Grid container className="domainlbl" spacing={3}>
              <Grid item md={4}>
                <CssTextField
                  id="standard-basic"
                  placeholder="Your Domain"
                  variant="standard"
                  value={enterDomain}
                  onChange={(e) => setEnterDomain(e.target.value)}
                  className="domainInput"
                />
                <p className="tagdomain">
                  Your private domain for your store. example:
                  yourcompanyname.com
                </p>
              </Grid>
            </Grid> */}
            <Box className="connectFullbtn">
            <Grid
              item
              md={12}
              style={{ float: "left" }}
             
              className="skipConnect"
            >
              {/* <Button sx={{ mt:0 }}
              onClick={createDomain}
              variant="text">
              <span className="skipspan">Skip</span>
            </Button> */}

              {renderConnectButton()}
            </Grid>
            </Box>



          </div>


          {/* <Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 5 }}
          />

          <Grid textAlign="left" item md={12}>
            <Button
              className="setup_btn account-setup"
              variant="text"
              sx={{ mt: 3, mb: 3 }}
              onClick={createDomain}
            >
              Next{" "}
              <Box className="arrowBox">
                <img
                  className="arrowbtn"
                  src="../assets/images/main/arrow.png"
                />
              </Box>
            </Button>
          </Grid> */}
<Grid container maxWidth="lg" md={12}>
  <Box className="seeDetails">
  <SeeDetailsDomain/>
  </Box>      
</Grid>




      
          <Divider
            style={{ width: "100%" }}
            className="divider"
            sx={{ mt: 8 }}
          />
          <Grid textAlign="left" item md={12}>
            <Button
              className="setup_btn account-setup"
              sx={{ mt: 2, mb: 2 }}
              variant="text"
              onClick={nextButtonClicked}
            >
              {Strings.NEXT_BUTTON_TITLE}
              <Box className="arrowBox">
                <img
                  className="arrowbtn"
                  src="../assets/images/main/arrow.png"
                />
              </Box>
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ConnectDomain;
